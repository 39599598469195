<template>
    <div class="home-index">
    
      <div class="nodata" >
        <el-empty class="img" :image-size="300"    description="系统维护中" :image="require('@/assets/img/maintenance.svg')"></el-empty>
        <!-- <img :src="require('@/assets/img/maintenance.svg')" class="img" />
        <div>系统维护中</div> -->
      </div>
    </div>
  </template>
  
  <script>
  import {BASE_V_API} from "@/config.js"
  import moment from "moment";
  import { mapState } from "vuex";
  export default {
    name: "systemMaintenance",
    data() {
      return {
        loading: false,
        data: [],
        results: "",
        base: "",
        select: "",
        sort: 5,
      };
    },
    computed: {
     
    },
    created() {
      this.loadData();
    },
    methods: {
      moment,
     
    },
    components: {
    
    },
  };
  </script>
  
  <style lang="less" scoped>
  
  .nodata {
    min-height: calc(100vh - 235px);
    .img {
      // width: 408px;
      // height: 639px;
      display: block;
      margin: 1.5% auto 0;
  
    }
  }
  </style>
  <style lang="less" >
  .nodata {
    min-height: calc(100vh - 235px);
    .img {
      // width: 408px;
      // height: 639px;
      display: block;
      margin: 1.5% auto 0;
      .el-empty__description{
        text-align: center;
        padding: 30px 0;
        font-size: 30px;
        color: #999;
      }
    }
  }
  </style>
  